$(document).on('turbolinks:load', function() {
  // show/hide search form
  $("#search-switch").click(function(e) {
    e.preventDefault();
    $('#activities_search').toggle(200);
  });
  // update the start_at and end_at dates
  $("[data-behaviour~='date_period']").click(function(e) {
    e.preventDefault();

    start = e.target.dataset.start;
    end = e.target.dataset.end;

    $("#q_start_at").val(start);
    $("#q_end_at").val(end);
  });
  // remove empty values when form is submit
  $('#activities_search form').submit(function() {
    return $(this).find(':input').filter(function() {
      return this.value === '';
    }).prop('disabled', true);
  });
});