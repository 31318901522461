const selectable_project_input = function() {
  const inputResult = function(s) {
    if (!s.id) {
      return s.text;
    }
    const {
      client,
      project
    } = s.element.dataset;

    return $('<b>' + client + '</b><br/>' + project + '</span>');
  };

  const inputSelection = function(s) {
    if (!s.id) {
      return s.text;
    }

    const {
      client,
      project
    } = s.element.dataset;

    return $('<b>' + client + '</b><br/>' + project + '</span>');
  };

  var selectable = $("[data-behaviour~='selectable_project']").select2({
    theme: 'bootstrap4',
    width: '100%',
    templateResult: inputResult,
    templateSelection: inputSelection
  });

  selectable.on("select2:open", function() {
    $('.select2-search__field').focus();
  });;
};

$(document).on('turbolinks:load', selectable_project_input);