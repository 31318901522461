$(document).on('turbolinks:load', function() {
  var selectable = $("[data-behaviour~='selectable']").select2({
    theme: 'bootstrap4',
    width: '100%'
  });
  selectable.on("select2:open", function() {
    $('.select2-search__field').focus();
  });;

  $("[data-behaviour~='selectable']").on('select2:select', function() {
    let event = new Event('change', {
      bubbles: true
    })
    this.dispatchEvent(event)
  })
});