$(document).on('turbolinks:load', function() {
  // show/hide search form
  $("#search-switch").click(function(e) {
    e.preventDefault();
    $('#search').toggle(200);
    $('#search input:first').focus();
  });
  // remove empty values when form is submit
  $('#search form').submit(function() {
    return $(this).find(':input').filter(function() {
      return this.value === '';
    }).prop('disabled', true);
  });
});