import {
  Controller
} from "stimulus"

import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["clients", "projects"]

  client_change(e) {
    const client_id = this.clientsTarget.value;
    Rails.ajax({
      type: "GET",
      url: "/projects.json",
      success: (data) => {
        this.updateProjects(data, client_id)
      },
    })
  }

  updateProjects(data, client_id) {
    this.projectsTarget.innerHTML = ""
    // create empty option
    const option = document.createElement("option")
    option.innerHTML = "Projets"
    option.value = ""
    this.projectsTarget.appendChild(option)
    // filter by client if selected
    if (client_id !== "") {
      data = data.filter(function(project) {
        return project.client_id == client_id
      })
    }
    // update with all the projects
    data.forEach((project) => {
      const option = document.createElement("option")
      option.value = project.id
      option.innerHTML = project.title
      this.projectsTarget.appendChild(option)
    })
  }

}